
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center text-end">
                        ชื่อ-สกุลลูกค้า:
                      </label>
                      <b-form-input
                        v-model="filter.customer"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="validateForm"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label
                        for="branchId "
                        class="d-inline align-items-center"
                        >วันที่นัดหมาย</label
                      >
                      <date-picker
                        v-model="filter.date"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                        :class="{
                          'is-invalid': submitform && $v.filter.date.$error,
                        }"
                      ></date-picker>
                      <div
                        v-if="submitform && $v.filter.date.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.filter.date.required">{{ error }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label for="cusSrcId" class="d-inline align-items-center">
                        ประเภท:</label
                      >
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.typeId"
                        :options="typeOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                        :class="{
                          'is-invalid': submitform && $v.filter.typeId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                      <div
                        v-if="submitform && $v.filter.typeId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.filter.typeId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 text-end">
                    <btnComponent @click="validateForm" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;

                    <!-- <router-link
                      :to="{ name: 'add-branchTestDrive' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                    </router-link> -->
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-8 col-md-6">
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="mb-3 position-relative">
                          <code>* </code>
                          <label
                            for="cusSrcId"
                            class="d-inline align-items-center"
                          >
                            ผู้รับผิดชอบ:</label
                          >
                          <multiselect
                            id="cusSrcId"
                            v-model="userId"
                            :options="rowDataUser"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getDataUser()"
                            :class="{
                              'is-invalid': submitformUser && $v.userId.$error,
                            }"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                          <div
                            v-if="submitformUser && $v.userId.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.userId.required">{{ error }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 text-end">
                        <div class="mb-3 position-relative">
                          <br />
                          <b-button
                            @click="valistoreC()"
                            variant="primary"
                            class="mb-2"
                            >ส่งติดต่อ</b-button
                          >&nbsp;
                          <b-button
                            @click="valistoreL()"
                            variant="success"
                            class="mb-2"
                            >ส่งแจ้งเตือนไลน์</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <!-- <div class="row">
                <div class="text-uppercase text-bold">
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @click="select"
                  />เลือกทั้งหมด
                </div>
               
              </div> -->
              <!-- <tableData :fields="fields" :items="rowData"> -->
              <b-table
                :items="rowData"
                :fields="fields"
                responsive="sm"
                class="border"
              >
                <template
                  v-slot:head(selectCC)="rowData"
                  v-if="rowData.length != 0"
                >
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @click="selectAlls"
                    class="form-check-input"
                  />
                  {{ rowData.label }}
                </template>
                <!-- <template
                  v-slot:head(selectLine)="rowData"
                  v-if="rowData.length != 0"
                >
                  <input
                    type="checkbox"
                    v-model="selectLineAll"
                    @click="selectLineAlls"
                  />
                  {{ rowData.label }}
                </template> -->
                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(selectCC)="rowData" v-if="rowData.length != 0">
                  <input
                    type="checkbox"
                    :value="rowData.item.testId"
                    v-model="selectCCs"
                    class="form-check-input"
                  />
                </template>
                <!-- <template
                  #cell(selectLine)="rowData"
                  v-if="rowData.length != 0"
                >
                  <input
                    type="checkbox"
                    :value="rowData.item.testId"
                    v-model="selectLines"
                  />
                </template> -->
                <template #cell(testDriveDate)="rowData">
                  <changeDate :date="rowData.item.testDriveDate"></changeDate>
                </template>
                <template #cell(status)="rowData">
                  <span
                    class="badge bg-warning font-size-12 ms-2"
                    v-if="rowData.item.status === 'DFT'"
                  >
                    ร่าง
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'SUBMIT'"
                  >
                    ยืนยันแล้ว
                  </span>
                </template>
                <!-- <template #cell(action)="rowData">
                  <b-button
                    @click="storeCC(rowData.item)"
                    size="sm"
                    variant="primary"
                    class="mb-2"
                    >ส่งติดต่อ</b-button
                  >&nbsp;
                  <b-button
                    @click="storeLineFlex(rowData.item)"
                    size="sm"
                    variant="success"
                    class="mb-2"
                    >ส่งแจ้งเตือนไลน์</b-button
                  >
                </template> -->
              </b-table>

              <br />

              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col-md-12">
                    <div class="align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </div>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import tableData from "@/components/tablecomponent";
import btnComponent from "@/components/btnComponent.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
import ChangeDate from "@/components/changeDateComponent";

export default {
  page: {
    title: appConfig.ppccNoti,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // tableData,
    btnComponent,
    Multiselect,
    DatePicker,
    ChangeDate,
  },

  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      loading: undefined,

      title: appConfig.ppccNoti,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.ppccNoti,
          active: true,
        },
      ],
      testId: "",
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      salaryLabel: "",
      rowDataUser: [],
      userId: "",
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      filter: {
        customer: "",
        date: "",
        branchId: "",
        typeId: "",
      },
      typeOption: [{ id: "TD", nameTh: "ลูกค้าทดลองขับ" }],
      occOption: [],
      statusOption: [
        { nameTh: "ร่าง", id: "DFT" },
        { nameTh: "เสร็จสิ้น", id: "SUBMIT" },
      ],
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      modelOption: [],
      branchIdSearch: "",
      localDataBranchId: [],
      filterOn: [],
      localData: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      statusLabel: "",
      submitform: false,
      selectCCs: [],
      selectAll: false,
      selectLineAll: false,
      indeterminate: true,
      selectLines: [],
      submitformUser: false,

      fields: [
        {
          key: "selectCC",
          label: "",
        },
        {
          key: "customerNameTh",
          label: "ชื่อ-สกุลลูกค้า",
          sortable: true,
        },
        {
          key: "testDriveDate",
          label: "วันที่นัดหมาย",
          sortable: true,
        },
        {
          key: "modelNameTh",
          label: "รุ่นรถ",
          sortable: true,
        },

        {
          key: "status",
          label: "สถานะ",
          sortable: true,
        },
        {
          key: "createdBy",
          label: "ผู้สร้าง",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "selectCC",
          label: "เลือกติดต่อทั้งหมด",
        },
        // {
        //   key: "selectLine",
        //   label: "เลือกแจ้งเตือนไลน์ทั้งหมด",
        // },
        // {
        //   key: "action",
        //   label: "",
        // },
      ],
    };
  },
  validations: {
    filter: {
      date: {
        required,
      },
      typeId: {
        required,
      },
    },
    userId: { required },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      // if (user.branchId == item.branchId) {
      //   this.branchIdSearch = {
      //     branchId: item.branchId,
      //     nameTh: item.nameTh,
      //   };
      // }
    });
    this.typeOption.forEach((e) => {
      this.filter.typeId = { nameTh: e.nameTh, id: e.id };
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // Set the initial number of items
    var date = new Date();
    this.filter.date = this.formatDate(date);
    this.getData();
    this.getDataUser();
  },
  created() {
    // this.getLocalData();
  },
  watch: {
    selectCCs(newValue) {
      if (newValue.length !== this.rowData.length) {
        this.selectAll = false;
      } else if (newValue.length === this.rowData.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    selectLines(newValue) {
      if (newValue.length !== this.rowData.length) {
        this.selectLineAll = false;
      } else if (newValue.length === this.rowData.length) {
        this.selectLineAll = true;
      } else {
        this.selectLineAll = false;
      }
    },
  },
  methods: {
    selectAlls() {
      this.selectCCs = [];
      if (!this.selectAll) {
        for (let i in this.rowData) {
          this.selectCCs.push(this.rowData[i].testId);
        }
      }
    },
    selectLineAlls() {
      this.selectLines = [];
      if (!this.selectLineAll) {
        for (let i in this.rowData) {
          this.selectLines.push(this.rowData[i].testId);
        }
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    print(Data) {
      this.data = Data;
      this.$router.push({
        name: "add-contactJob",
        params: {
          data: this.data.item,
        },
      });
    },
    validateForm() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.filter.$invalid != true) {
        this.handleSearch();
      }
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [
          this.branchIdSearch ? this.branchIdSearch.branchId : this.branchId,
        ];
      }
      this.getData();
      this.getDataUser();
    },
    onFiltered(filteredItems) {
      this.rowData = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    getData: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/pp-cc-notify", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
            customer: this.filter.customer,
            type: this.filter.typeId ? this.filter.typeId.id : "",
            date: this.filter.date,
            // modelId: this.filter.modelId ? this.filter.modelId.modelId : "",
            // testDriveDate: this.filter.date,
          },
        })
        .then((response) => {
          this.selectAll = false;

          this.rowData = response.data.data;
          const per_Page = response.data.perPage;

          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
          //  this.getDataUser();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataUser: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/pp-cc-notify/branch-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch
              ? this.branchIdSearch.branchId
              : this.branchId,
          },
        })
        .then((response) => {
          this.rowDataUser = response.data.data;
          // this.perPage = response.data.perPage;
          // this.from = response.data.from;
          // this.totalRecord = response.data.total;
          // this.to = response.data.to;
          // this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    valistoreC() {
      this.$v.$touch();
      this.submitformUser = true;
      if (this.$v.$invalid != true) {
        this.valistoreCC();
      }
    },
    valistoreL() {
      this.$v.$touch();
      this.submitformUser = true;
      if (this.$v.$invalid != true) {
        this.valistoreLine();
      }
    },
    valistoreCC() {
      if (this.selectCCs.length >= 1) {
        this.storeCC();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณาเลือกรายการส่งติดต่อ",
          appConfig.swal.type.error
        );
      }
    },
    valistoreLine() {
      if (this.selectCCs.length >= 1) {
        this.storeLineFlex();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณาเลือกรายการส่งแจ้งเตือนไลน์",
          appConfig.swal.type.error
        );
      }
    },
    storeCC: function () {
      this.overlayFlag = true;
      useNetw
        .post("api/pp-cc-notify/store-contact-job", {
          testId: this.selectCCs,
          userId: this.userId.userId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          this.selectCCs = [];
          this.submitformUser = false;
          this.userId = "";
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            // err.message +
            //   "<br>" +
            // err.response.data.message +
            // "<br>" +
            err.response.data.validationMessage[0],
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    storeLineFlex: function () {
      this.overlayFlag = true;
      // const bodyFormData = new FormData();

      // // // parse
      // bodyFormData.append("testId[]", data.testId);
      useNetw
        .post("api/pp-cc-notify/store-line-flex", {
          testId: this.selectCCs,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          this.selectCCs = [];
          this.submitformUser = false;
          this.userId = "";
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            // err.message +
            //   "<br>" +
            JSON.stringify(err.response.data.message),
            // "<br>" +
            // err.response.data.validationMessage,
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    delete: function (rowData) {
      this.data = rowData;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/test-drive/delete", {
          data: {
            testId: this.data,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>